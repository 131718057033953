


























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { TimeUnits } from '../types/questions.type';

@Component
export default class DurationComponent extends Vue {
    @Prop({ default: true }) readonly labeled!: boolean;

    @Prop(String) readonly value!: string;

    timeUnits: TimeUnits = { second: 0, minute: 0, hour: 0 };

    rules = {
      timeUnit: [
        (val: number) => !!val || val === 0
            || this.$t('validations.integerValue'),
        (val: number) => val >= 0
            || this.$t('validations.minValue', { min: 0 }),
        (val: number) => val <= 59
            || this.$t('validations.maxValue', { max: 59 }),
        (val: number) => Number(val) === Math.round(val)
            || this.$t('validations.integerValue'),
      ],
      timeHour: [
        (val: number) => !!val || val === 0
            || this.$t('validations.integerValue'),
        (val: number) => val >= 0
            || this.$t('validations.minValue', { min: 0 }),
        (val: number) => val <= 9
            || this.$t('validations.maxValue', { max: 9 }),
        (val: number) => Number(val) === Math.round(val)
            || this.$t('validations.integerValue'),
      ],
    }

    @Watch('timeUnits', { deep: true })
    onTimeChange(newVal: TimeUnits) {
      const { second, minute, hour } = this.formalizeTime(newVal);
      this.$emit('input', `${hour}:${minute}:${second}`);
    }

    mounted() {
      this.setTimeObj();
    }

    setTimeObj() {
      const [hour, minute, second] = this.value?.split(':') || [0, 0, 0];
      this.timeUnits = {
        second: (second && Number(second)) || 0,
        minute: (minute && Number(minute)) || 0,
        hour: (hour && Number(hour)) || 0,
      };
    }

    formalizeTime(timeUnitsObj: TimeUnits) {
      const timeObj = timeUnitsObj;
      const formalizedTime = { second: '00', minute: '00', hour: '00' };
      Object.keys(timeObj).forEach((key) => {
        if (timeObj[key]) {
          formalizedTime[key] = timeObj[key] <= 9 ? `0${timeObj[key]}` : timeObj[key];
        } else { timeObj[key] = 0; }
      });

      return formalizedTime;
    }
}
